import {BrowserRouter, useHistory} from 'react-router-dom'
import * as Config from "config"
import * as ReactDOM from "react-dom";
import * as React from "react";
import {Button, Redirect, TitleBar} from "@shopify/app-bridge/actions";
import {Provider, useAppBridge} from "@shopify/app-bridge-react";
import Routes from "./routes";
import {authenticatedFetch} from "@shopify/app-bridge-utils";
import {ShopifyAppContext} from "./shopify_context";

const Raven = require('raven-js');

require('bootstrap');
var mixpanel = require('mixpanel-browser');

const icon = require('./images/icon@2x.png');
export const storeName = new URLSearchParams(window.location.search).get('shop')

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

Number.isInteger = Number.isInteger || function (value) {
    return typeof value === "number" &&
        isFinite(value) &&
        Math.floor(value) === value;
};

// http://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

if (Config.sentryDsn !== null) {
    Raven.config(Config.sentryDsn, {
        release: RAVEN_RELEASE
    }).install();
}

function ShopifyApp(props) {
    const app = useAppBridge()
    const appFetch = authenticatedFetch(app)
    const history = useHistory()

    const titleBar = TitleBar.create(app)

    const newEstimateButton = Button.create(app, {label: 'New Estimate'})
    newEstimateButton.subscribe(Button.Action.CLICK, data => {
        history.push('/pipeline/new')
    })

    const estimatesButton = Button.create(app, {label: 'Estimates'})
    estimatesButton.subscribe(Button.Action.CLICK, data => {
        history.push('/')
    })

    const helpButton = Button.create(app, {label: 'Help'})
    helpButton.subscribe(Button.Action.CLICK, data => {
        history.push('/help')
    })

    const settingsButton = Button.create(app, {label: 'Settings'})
    settingsButton.subscribe(Button.Action.CLICK, data => {
        history.push('/settings')
    })

    titleBar.set({
        buttons: {
            primary: newEstimateButton,
            secondary: [estimatesButton, helpButton, settingsButton]
        }
    })

    appFetch(Config.apiHostName + '/users/has_active_charge').then(r => {
        if (r.status === 200) {            return r

        }
    }).then(r => {
        return r.json()
    }).then(json => {
        if (json.active !== true) {
            const redirect = Redirect.create(app);
            redirect.dispatch(Redirect.Action.REMOTE, Config.apiHostName + `/users/authorize_shopify?shop=${storeName}`)
        }
    })

    return <ShopifyAppContext.Provider value={{app: app, appFetch: appFetch, titleBar: titleBar}}>{props.children}</ShopifyAppContext.Provider>;
}

function renderApp() {
    if (Config.mixpanelApiKey !== null) {
        mixpanel.init(Config.mixpanelApiKey);
        mixpanel.identify("shopify_" + storeName)
    }

    ReactDOM.render((
        <BrowserRouter>
            <Provider config={{
                apiKey: SHOPIFY_API_KEY,
                host: new URLSearchParams(window.location.search).get('host'),
                forceRedirect: true
            }}>
                <ShopifyApp>
                    <Routes/>
                </ShopifyApp>
            </Provider>
        </BrowserRouter>
    ), document.getElementById('time-to-ship-app'))
}

if (window.top === window.self) {
    window.location.replace(Config.apiHostName + `/users/authorize_shopify?shop=${storeName}`)
} else {
    renderApp()
}