import * as React from "react";

export class DynamicTimeValueFormGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            currentValue: null,
            rawValue: "",
            timeUnit: "DAYS",
            receivedInitialValue: false
        };

        this.handleValueChange = this.handleValueChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialValue !== null && this.state.receivedInitialValue === false) {
            this.setState({
                receivedInitialValue: true
            });

            if (nextProps.initialTimeUnit === "DAYS") {
                this.handleValueChange(nextProps.initialValue / 24 / 60 / 60 / 1000, nextProps.initialTimeUnit);
            } else if (nextProps.initialTimeUnit === "HOURS") {
                this.handleValueChange(nextProps.initialValue / 60 / 60 / 1000, nextProps.initialTimeUnit);
            }
        }
    }

    handleValueChange(value, timeUnit) {
        let numberValue = Number(value);
        let newValidity = null;
        let newValue = null;

        if (isNaN(numberValue) || parseFloat(value) !==  parseFloat(value) || !Number.isInteger(parseFloat(value))) {
            newValidity = false;
            newValue = null;
        } else {
            newValidity = true;
            newValue = numberValue;
        }

        this.setState({
            valid: newValidity,
            currentValue: newValue,
            timeUnit: timeUnit,
            rawValue: value
        });

        if (timeUnit === "DAYS") {
            this.props.onValidityChanged(newValidity, newValue * 24 * 60 * 60 * 1000, timeUnit);
        } else if (timeUnit === "HOURS") {
            this.props.onValidityChanged(newValidity, newValue * 60 * 60 * 1000, timeUnit);
        }
    }

    render() {
        let invalidValueFormClass = null;
        let invalidValueInputClass = null;
        let invalidValueError = null;

        if (!this.state.valid && this.state.rawValue.length > 0) {
            invalidValueError = <div className="form-control-feedback">
                Please enter a positive number.
            </div>;
            invalidValueFormClass = "has-danger";
            invalidValueInputClass = "form-control-danger";
        }

        return <div className={"form-group row " + invalidValueFormClass}>
            <label htmlFor="daysToShip" className="col-3 col-form-label-lg col-form-label">Each Order Adds</label>
            <div className="col-5">
                <input type="text"
                       className={"form-control-lg form-control " + invalidValueInputClass}
                       id="daysToShip"
                       name="daysToShip"
                       value={this.state.rawValue}
                       disabled={!this.props.enabled}
                       onChange={e => this.handleValueChange((e.target).value, this.state.timeUnit)}/>
                {invalidValueError}
            </div>
            <div className="col-4">
                <select className="custom-select form-control form-control-lg"
                        disabled={!this.props.enabled}
                        value={this.state.timeUnit}
                        onChange={e => this.handleValueChange(this.state.rawValue, e.target.value)}>
                    <option value="DAYS">Days</option>
                    <option value="HOURS">Hours</option>
                </select>
            </div>
        </div>
    }
}