import * as React from "react";
import * as ReactDOM from "react-dom";

export class EstimateType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: "STATIC",
        };

        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            currentValue: nextProps.initialValue
        });
    }

    componentDidMount() {
        $(ReactDOM.findDOMNode(this.refs.toggle)).tooltip();
    }

    handleTypeChange(newType) {
        this.setState({
            currentValue: newType
        });
        this.props.onValueChanged(newType)
    }

    render() {
        let disabledTooltip = null;
        if (this.props.dynamicDisabled) {
            disabledTooltip = "";
        }

        return <div className="row">
            <div className="col-3">
                <label htmlFor="estimateType" className="col-form-label col-form-label-lg">Estimate Type</label>
            </div>
            <div className="col-9">
                <fieldset className="form-group">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio"
                                   className="form-check-input"
                                   name="estimateType"
                                   id="static"
                                   value="STATIC"
                                   checked={this.state.currentValue === 'STATIC'}
                                   onChange={e => this.handleTypeChange(e.target.value)}
                                   disabled={!this.props.enabled}/>
                            Static - time to ship estimate stays fixed for every order.
                        </label>
                    </div>

                    <div className="form-check" ref="toggle" data-toggle="tooltip" data-placement="left" title="Only individual products support dynamic estimates.">
                        <label className="form-check-label">
                            <input type="radio"
                                   className="form-check-input"
                                   name="estimateType"
                                   value="DYNAMIC"
                                   checked={this.state.currentValue === 'DYNAMIC'}
                                   onChange={e => this.handleTypeChange(e.target.value)}
                                   disabled={!this.props.enabled || this.props.dynamicDisabled}/>
                            Dynamic - time to ship estimate grows as order queue grows.
                        </label>
                    </div>
                </fieldset>
            </div>
        </div>
    }
}