import * as React from "react";
import * as EmbeddedAppUtils from "../utils/embedded_app_utils";
import * as Config from "config";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import {authenticatedFetch} from "@shopify/app-bridge-utils";
import {ShopifyAppContext} from "../shopify_context";

require("open-iconic/font/css/open-iconic-bootstrap.scss");

const Raven = require('raven-js');

export class CollectionsScreen extends React.Component {
    static contextType = ShopifyAppContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            updating: false,
            collections: [],
            error: null
        };

        this.fetchCollections = this.fetchCollections.bind(this);
    }

    componentDidMount() {
        EmbeddedAppUtils.appTitle(this.context.titleBar, "Collections Settings");

        const componentThis = this;

        this.windowAlertHandler = window.addEventListener("beforeunload", function (e) {
            if (componentThis.state.updating) {
                const confirmationMessage = "Changes you made may not be saved.";
                (e || window.event).returnValue = confirmationMessage;     //Gecko + IE
                return confirmationMessage;                                //Webkit, Safari, Chrome etc.
            }
        });

        this.fetchCollections()
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.windowAlertHandler)
    }

    fetchCollections() {
        this.setState({
            loading: true,
            collections: [],
            error: null
        });

        const screenThis = this;

        this.context.appFetch(Config.apiHostName + '/app/collections/', {credentials: 'include'})
            .then(function (response) {
                if (response.status === 200) {
                    return response
                } else {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error
                }
            })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                screenThis.setState({
                    loading: false,
                    collections: json,
                    error: null
                });
            })
            .catch(function (error) {
                Raven.captureException(error);

                screenThis.setState({
                    loading: false,
                    error: "Unable to fetch collections."
                })
            })

    }

    render() {
        if (this.state.error != null) {
            return <div style={{textAlign: 'center'}}>
                {this.state.error}
                <br/>
                <br/>
                <button type="button" className="btn btn-primary" onClick={this.fetchCollections}>
                    Retry
                </button>

            </div>
        } else {
            const leftStyle = {
                float: 'left'
            };

            const rightStyle = {
                float: 'right'
            };

            const onSortEnd = ({oldIndex, newIndex}) => {
                const componentThis = this;

                this.setState({
                    collections: arrayMove(this.state.collections, oldIndex, newIndex),
                    updating: true
                });

                this.context.appFetch(Config.apiHostName + '/app/collections/order', {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({sort: this.state.collections.map((value) => value.id)})
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            componentThis.setState({
                                updating: false
                            });

                            return response
                        } else {
                            const error = new Error(response.statusText);
                            error.response = response;
                            throw error
                        }
                    })
                    .catch(function (error) {
                        Raven.captureException(error);

                        componentThis.setState({
                            updating: false,
                            error: "Unable to update collection order."
                        })
                    })
            };

            const SortableItem = SortableElement(({value}) => {
                return <li className="list-group-item">
                    <div style={leftStyle}>{value.title}</div>
                    <div style={rightStyle}><span className="oi oi-menu"/></div>
                </li>
            });

            const SortableList = SortableContainer(({items}) => {
                return <ul className="list-group">
                    {items.map((value, index) => (
                        <SortableItem key={`item-${index}`} index={index} value={value}/>
                    ))}
                </ul>
            });

            return <div className="row">
                <div className="col-sm-8">
                    <SortableList items={this.state.collections} onSortEnd={onSortEnd}/>
                </div>
                <div className="col-sm-4">
                    <h4>Collections Ordering</h4>
                    <p>
                        When a product is in multiple collections and does not have its own estimates, it's possible
                        that there are multiple estimates that apply to it.
                        <br/><br/>
                        On this page, you can set precedence for which collection should take priority when there is an
                        estimate from multiple collections.
                        <br/><br/>
                        Collections at the top of the list have higher priority than collections at the bottom. Drag
                        collections to set their precedence.
                    </p>
                </div>
            </div>
        }
    }
}
