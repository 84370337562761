import * as React from "react";

export const ASCENDING = "asc";
export const DESCENDING = "desc";
export const NO_SETTING = "no_setting";

export class SortableTableHeader extends React.Component {
    constructor() {
        super();
        this.changeSort = this.changeSort.bind(this)
    }

    changeSort() {
        let nextSort = null;
        switch(this.props.currentSort) {
            case ASCENDING:
                nextSort = DESCENDING;
                break;
            case DESCENDING:
                nextSort = ASCENDING;
                break;
            case NO_SETTING:
                nextSort = ASCENDING;
                break;
        }

        this.props.onSortChanged(nextSort);
    }

    render() {
        let symbol = null;
        switch(this.props.currentSort) {
            case ASCENDING:
                symbol = "▲";
                break;
            case DESCENDING:
                symbol = "▼";
                break;
            case NO_SETTING:
                symbol = "⇕";
                break;
        }

        return <th onClick={this.changeSort} style={this.props.style}>
            {this.props.value} <small>{symbol}</small>
        </th>
    }
}