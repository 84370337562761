import * as React from 'react';
import {EstimateFormatEditor} from "../components/EstimateFormatEditor";
import * as Config from "config";
import * as EmbeddedApUtils from "../utils/embedded_app_utils";
import {FormatTemplateReference} from "../components/FormatTemplateReference";
import {ShopifyAppContext} from "../shopify_context";
import {withRouter} from "react-router";

const loadingGif = require("../images/loading.gif").default;

class NewTemplateScreen extends React.Component {
    static contextType = ShopifyAppContext;

    constructor(props) {
        super(props);

        this.state = {
            existingId: this.props.match.params.templateId,
            templateName: '',
            estimateFormatString: '',
            estimateFormatStringValid: false,
            loading: false
        };

        this.createTemplate = this.createTemplate.bind(this);
        this.fetchTemplate = this.fetchTemplate.bind(this);
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.handleTemplateNameChange = this.handleTemplateNameChange.bind(this);
    }

    componentDidMount() {
        EmbeddedApUtils.appTitle(this.context.titleBar, "Create New Format Template");

        if (this.state.existingId !== undefined) {
            this.fetchTemplate()
        }
    }

    fetchTemplate() {
        const self = this;

        this.context.appFetch(Config.apiHostName + '/app/settings/templates/' + this.state.existingId, {
            credentials: 'include'
        }).then(function (response) {
            return response.json()
        }).then(function (json) {
            self.setState({
                templateName: json.name,
                estimateFormatString: json.format,
                outOfStockFormatString: json.outOfStockFormat,
                estimateFormatStringValid: true
            })
        })
    }

    handleFormatChange(newValue, newOutOfStockValue, isValid) {
        this.setState({
            estimateFormatString: newValue,
            outOfStockFormatString: newOutOfStockValue,
            estimateFormatStringValid: isValid,
        })
    }

    handleTemplateNameChange(newName) {
        this.setState({
            templateName: newName
        })
    }

    createTemplate() {
        const componentThis = this;

        this.setState({
            loading: true
        });

        if (this.state.existingId === undefined) {
            this.context.appFetch(Config.apiHostName + "/app/settings/templates", {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.state.templateName,
                    format: this.state.estimateFormatString,
                    outOfStockFormat: this.state.outOfStockFormatString
                })
            })
                .then(function (response) {
                    componentThis.setState({
                        loading: false
                    });

                    if (response.status === 200) {
                        componentThis.props.history.push('/settings/templates')
                    } else {
                        EmbeddedApUtils.appAlert(this.context.app, "Error creating template. If this problem persists please contact support.")
                    }
                })
        } else {
            this.context.appFetch(Config.apiHostName + "/app/settings/templates/" + this.state.existingId, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.state.exisitingId,
                    name: this.state.templateName,
                    format: this.state.estimateFormatString,
                    outOfStockFormat: this.state.outOfStockFormatString
                })
            })
                .then(function (response) {
                    componentThis.setState({
                        loading: false
                    });

                    if (response.status === 200) {
                        componentThis.props.history.push('/settings/templates')
                    } else {
                        EmbeddedApUtils.appAlert(this.context.app, 'Error updating template. If this problem persists please contact support.')
                    }
                })
        }
    }

    render() {
        let loading = null;
        if (this.state.loading) {
            loading = <span>
            &nbsp;&nbsp;
                <img src={loadingGif} alt="Loading..."/>
        </span>
        }


        const templateNameValid = this.state.templateName.length > 0;
        let invalidNameValueError = null;
        let invalidNameInputClass = null;
        if (!templateNameValid) {
            invalidNameInputClass = 'is-invalid';
            invalidNameValueError = <div className="invalid-feedback">
                Template must have a name.
            </div>
        }

        const isInvalid = !templateNameValid || !this.state.estimateFormatStringValid;

        const buttonCopy = this.state.existingId !== undefined ? "Update Template" : "Create Template";

        return (
            <div>
                <div className="form-group row">
                    <div className="col-3">
                        <label className="col-form-label col-form-label-lg">Template Name</label>
                    </div>
                    <div className="col-9">
                        <input type="text"
                               className={"form-control-lg form-control " + invalidNameInputClass}
                               onChange={e => this.handleTemplateNameChange(e.target.value)}
                               value={this.state.templateName}
                               disabled={this.props.loading}/>

                        {invalidNameValueError}
                    </div>
                </div>

                <EstimateFormatEditor
                    estimateFormatString={this.state.estimateFormatString}
                    estimateFormatStringValid={this.state.estimateFormatStringValid}
                    outOfStockFormatString={this.state.outOfStockFormatString}
                    onEstimateFormatChanged={this.handleFormatChange}/>

                <div className="row">
                    <div className="col-12">
                        <button type="submit"
                                className="btn btn-primary btn-lg"
                                disabled={this.state.loading || isInvalid}
                                onClick={this.createTemplate}>
                            {buttonCopy}
                        </button>
                        {loading}
                    </div>
                </div>

                <br/>

                <div className="row">
                    <div className="col-12">
                        <FormatTemplateReference/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewTemplateScreen)