import * as React from "react";
import * as EmbeddedAppUtils from "../utils/embedded_app_utils";
import * as Config from "config";
import {Link} from "react-router-dom";
import {ShopifyAppContext} from "../shopify_context";

const editHtmlCssImage1x = require('../images/getting_started/edit_html_css@1x.png');
const editHtmlCssImage2x = require('../images/getting_started/edit_html_css@2x.png');

var mixpanel = require('mixpanel-browser');

export class HelpScreen extends React.Component {
    static contextType = ShopifyAppContext;

    componentDidMount() {
        EmbeddedAppUtils.appTitle(this.context.titleBar, "Help");
    }

    render() {
        const exampleSnippet = "<p id=\"fulfillment-estimates-snippet\"></p>";

        return <div className="row">
            <div className="col-12">
                <h2>Help</h2>
                <p>
                    Thank you for installing Fulfillment Estimates! If you run into any issues, or have any kind of
                    feedback, please don’t hesitate to reach out to us at <a
                    target="_blank"
                    href="mailto:support@fulfillmentestimates.com">support@fulfillmentestimates.com</a>.
                </p>

                <dl>
                    <dt>Can I customize the placement of the estimate?</dt>
                    <dd>Yes! Simply add <code>{exampleSnippet}</code> to your shop's theme where you want the estimate
                        to appear.
                    </dd>

                    <dt>Can I customize the format of the estimate?</dt>
                    <dd>Yes! Click on settings in the menu at the top of the page. In the Settings screen, you can
                        customize how the estimate is formatted.
                    </dd>

                    <dt>Can I add an estimate for an entire collection of products?</dt>
                    <dd>
                        Yes! You can add static estimates for both smart and custom product collections.
                        Simply type in the collection name in the new estimate form.
                        <br/><br/>
                        If a product belongs to multiple collections, you can customize the priority of each collection
                        on <Link to="/settings/collections">this page</Link>.
                    </dd>

                    <dt>I only ship orders on certain days of the week, can Fulfillment Estimates incorporate this into
                        its estimates?
                    </dt>
                    <dd>Yes! Click on settings in the menu at the top of the page. In the Settings screen, choose "Round
                        to specific day of week" and select which weekdays you ship products on. Estimates will
                        automatically round up to the next available day in which products are shipped.
                    </dd>

                    <dt>My question isn't answered, how can I get help?</dt>
                    <dd>Email us at <a
                        target="_blank"
                        href="mailto:support@fulfillmentestimates.com">support@fulfillmentestimates.com</a> and we'll be
                        glad to help
                        you out.
                    </dd>

                    <dt>What is the difference between a dynamic estimate and a static estimate?</dt>
                    <dd>Static estimates are a fixed amount of time. Dynamic estimates will grow as more unfulfilled
                        orders come in.
                    </dd>
                </dl>

                <p>
                    Thanks for using Fulfillment Estimates!
                </p>
            </div>
        </div>
    }
}