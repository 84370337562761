import * as React from "react";

export class InlineCheckbox extends React.Component {
    render() {
        return <div className="form-check form-check-inline">
            <label className="form-check-label">
                <input className="form-check-input" type="checkbox"
                       value={this.props.value}
                       checked={this.props.checked}
                       onChange={e =>
                           this.props.handleChange(e.target.value, e.target.checked)
                       }/> {this.props.displayText}
            </label>
        </div>
    }
}