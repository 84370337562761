import * as React from 'react';
import * as Config from 'config';
import * as Dialogs from "../utils/embedded_app_utils";
import {Link} from "react-router-dom";
import {ShopifyAppContext} from "../shopify_context";

const loadingGif = require("../images/loading.gif").default;

export class TemplatesScreen extends React.Component {
    static contextType = ShopifyAppContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false
        };

        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.fetchTemplates = this.fetchTemplates.bind(this);
    }

    componentWillMount() {
        this.fetchTemplates()
    }

    fetchTemplates() {
        this.setState({
            loading: true,
            error: false
        });

        const self = this;

        this.context.appFetch(Config.apiHostName + '/app/settings/templates', {
            credentials: 'include'
        }).then(function (response) {
            return response.json()
        }).then(function (json) {
            self.setState({
                loading: false,
                error: false,
                templates: json
            })
        }).catch(function (err) {
            // TODO Sentry?
            self.setState({
                loading: false,
                error: true
            })
        })
    }

    deleteTemplate(id) {
        const self = this;

        Dialogs.appConfirm(this.context.app,"Are you sure you want to delete this template? Estimates using this template will be switched to use the default template.", function (result) {
            if (result === true) {
                this.context.appFetch(Config.apiHostName + '/app/settings/templates/' + id, {
                    credentials: "include",
                    method: "DELETE"
                })
                    .then(function (response) {
                        if (response.status !== 200) {
                            // TODO: Sentry.
                            alert('Error deleting template. If this problem persists, please contact support.')
                        } else {
                            self.fetchTemplates()
                        }
                    })
            }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="row text-center">
                    <div className="col-12">
                        <span className="text-center">
                            <img src={loadingGif}/>
                        </span>
                    </div>
                </div>
            );
        } else if (this.state.error) {
            return (
                <div className="row text-center">
                    <div className="col-12">
                        Error fetching templates.
                        <br/>
                        <br/>
                        <button type="button" className="btn btn-primary" onClick={this.fetchTemplates}>
                            Retry
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Format
                                </th>
                                <th className="text-right">
                                    <Link to="/settings/templates/new">
                                        <btn className="btn btn-primary">New Template</btn>
                                    </Link>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.templates.map((template, idx) => (
                                <tr key={template.id}>
                                    <td>{template.name}</td>
                                    <td>{template.format}</td>
                                    <td className="text-right">
                                        <Link to={"/settings/templates/" + template.id}>Edit</Link>
                                        &nbsp;/&nbsp;
                                        <a href="#" onClick={(e) => {
                                            this.deleteTemplate(template.id)
                                        }}>Delete</a>
                                    </td>
                                </tr>
                            ))}
                            {this.state.templates.length == 0 ? (
                                <tr>
                                    <td colSpan="4" style={{textAlign: 'center'}}>
                                        <h4>
                                            <Link to="/settings/templates/new">Create your first custom template</Link>
                                        </h4>
                                    </td>
                                </tr>
                            ) : null}

                            <tr>
                                <td colSpan="4" className="text-center">
                                    <p>
                                        Want to edit the default template? <Link to="/settings">Click here to go to
                                        settings</Link> to edit the default template.
                                    </p>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }
    }
}