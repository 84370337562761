import * as React from "react";

export class SubmitButton extends React.Component {
    render() {
        // TODO: Make the copy dynamic.
        return <button type="submit"
                       className="btn btn-primary btn-lg"
                       disabled={!this.props.enabled}
                       onClick={this.props.onClick}>
            Save
        </button>
    }
}
