import * as React from "react";

export class TimeValueFormGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            currentValue: null,
            rawValue: "",
            receivedInitialValue: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialValue != null && this.state.receivedInitialValue == false) {
            this.setState({
                receivedInitialValue: true
            });
            this.handleDaysToShipChange(nextProps.initialValue / 24 / 60 / 60 / 1000);
        }
    }

    render() {
        let invalidValueError = null;
        let invalidValueFormClass = null;
        let invalidValueInputClass = null;

        if (!this.state.valid && this.state.rawValue.length > 0) {
            invalidValueError = <div className="form-control-feedback">
                Please enter a positive number.
            </div>;
            invalidValueFormClass = "has-danger";
            invalidValueInputClass = "form-control-danger";
        }

        return <div className={"form-group row " + invalidValueFormClass}>
            <label htmlFor="daysToShip" className="col-3 col-form-label-lg col-form-label">Days to Ship</label>
            <div className="col-9">
                <input type="text"
                       className={"form-control-lg form-control " + invalidValueInputClass}
                       id="daysToShip"
                       name="daysToShip"
                       value={this.state.rawValue}
                       disabled={!this.props.enabled}
                       onChange={e => this.handleDaysToShipChange((e.target).value)}/>
                {invalidValueError}
            </div>

        </div>
    }

    handleDaysToShipChange(value) {
        let numberValue = Number(value);
        let newValidity = null;
        let newValue = null;

        if (isNaN(numberValue) || parseFloat(value) !== parseFloat(value)  || !Number.isInteger(parseFloat(value))) {
            newValidity = false;
            newValue = null;
        } else {
            newValidity = true;
            newValue = numberValue;
        }

        this.setState({
            valid: newValidity,
            currentValue: newValue,
            rawValue: value
        });

        if (this.props.onValidityChanged != null) {
            this.props.onValidityChanged(newValidity, newValue * 24 * 60 * 60 * 1000)
        }
    }
}