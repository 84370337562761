import * as React from "react";
import {useAppFetch} from "../index";
import {useHistory, useLocation} from "react-router";
import * as Config from "config"
import {ShopifyAppContext} from "../shopify_context";

const loadingGif = require("../images/loading.gif").default;

function InternalRedirect(props) {
    const queryParams = new URLSearchParams(useLocation().search);
    const history = useHistory();

    props.appFetch(Config.apiHostName + `/app/items/app_link?id=${queryParams.get("id")}`)
        .then(r => {
            if (r.status === 200) {
                return r
            } else {
                const error = new Error(r.statusText);
                error.response = r;
                throw error
            }
        })
        .then(r => r.json())
        .then(json => history.push(json.frontendPath))

    return <div align="center">
        <img src={loadingGif} alt="Loading..."/>
    </div>
}

export function ProductAppLinkRedirect(props) {
    return <ShopifyAppContext.Consumer>
        <InternalRedirect appFetch={value.appFetch}/>
    </ShopifyAppContext.Consumer>
}