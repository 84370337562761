import * as React from "react";
import * as Config from "config"
import * as EmbeddedAppUtils from "../utils/embedded_app_utils"
import {DynamicTimeValueFormGroup} from '../components/DynamicTimeValueFormGroup'
import {EstimateType} from '../components/EstimateType'
import {ProductOrCategoryTypeahead} from '../components/ProductOrCategoryTypeahead'
import {SubmitButton} from '../components/SubmitButton'
import {TimeValueFormGroup} from '../components/TimeValueFormGroup'
import {BackendError} from "../BackendError";
import {TemplateToUse} from "../components/TemplateToUse";
import qs from "qs";
import {withRouter} from "react-router";
import {ShopifyAppContext} from "../shopify_context";

const ERROR_CODE_PIPELINE_EXISTS = "create_pipeline.pipeline_exists";

const loadingGif = require("../images/loading.gif").default;

class PipelineEditorScreen extends React.Component {
    static contextType = ShopifyAppContext;

    constructor(props) {
        super(props);

        const { pipelineId } = this.props.match.params;
        const parsedQueryParams = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})

        // TODO: Validate that this is a number?
        if (pipelineId !== null && pipelineId !== 'new') {
            this.state = {
                productOrCategoryValueValid: true,
                timeValueValid: true,
                dynamicTimeValueValid: true,
                pendingRequest: true,
                selectedItem: null,
                timeValue: null,
                dynamicTimeValue: null,
                overwriteApproved: true,
                editingMode: true,
                pipelineType: null,
                dynamicTimeUnit: null,
            };
        } else {
            let selectedItem = null;
            let selectedItemValid = false;

            if (parsedQueryParams.shopifyProductId !== null && parsedQueryParams.shopifyProductTitle !== null) {
                selectedItemValid = true;
                selectedItem = {
                    identifier: parseFloat(parsedQueryParams.shopifyProductId),
                    title: parsedQueryParams.shopifyProductTitle,
                    type: "PRODUCT"
                }
            }

            this.state = {
                productOrCategoryValueValid: selectedItemValid,
                timeValueValid: false,
                dynamicTimeValueValid: false,
                pendingRequest: false,
                selectedItem: selectedItem,
                timeValue: null,
                dynamicTimeValue: null,
                overwriteApproved: false,
                editingMode: false,
                pipelineType: "STATIC",
                dynamicTimeUnit: "DAYS",
            };
        }

        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onEstimateTypeChanged = this.onEstimateTypeChanged.bind(this);
        this.onHandleTemplateChanged = this.onHandleTemplateChanged.bind(this);
        this.onDynamicTimeValueValidityChanged = this.onDynamicTimeValueValidityChanged.bind(this);
    };

    componentDidMount() {
        EmbeddedAppUtils.appTitle(this.context.titleBar, "Estimate Editor");

        let editorScreenThis = this;

        const { pipelineId } = this.props.match.params;
        const parsedQueryParams = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})

        if (this.state.editingMode) {
            this.context.appFetch(Config.apiHostName + "/app/pipelines/" + pipelineId, {
                credentials: 'include'
            }).then(function (response) {
                if (response.status == 200) {
                    return response.json().then(function (json) {
                        let identifier = null;
                        let title = null;
                        let type = null;
                        if (json.product !== null) {
                            identifier = json.product.shopifyId;
                            title = json.product.title;
                            type = "PRODUCT"
                        } else if (json.collection !== null) {
                            identifier = json.collection.shopifyId;
                            title = json.collection.title;
                            type = json.collection.type;
                        }

                        let templateId = null;
                        if (json.template != null) {
                            templateId = json.template.id;
                        }

                        editorScreenThis.setState({
                            // TODO: Make some kind of spec for this.
                            // TODO: Support categories.
                            selectedItem: {
                                identifier: identifier,
                                title: title,
                                type: type,
                            },
                            timeValue: json.timeInMilliseconds,
                            dynamicTimeValue: json.eachOrderTimeInMilliseconds,
                            dynamicTimeUnit: json.eachOrderTimeUnit,
                            pipelineType: json.type,
                            templateId: templateId,
                            pendingRequest: false
                        });
                    })
                } else {
                    // TODO: Error flow.
                }
            }).catch(function (ex) {
                console.error(ex);
                // TODO: Sentry this.
            })
        }
    }

    onHandleTemplateChanged(templateId) {
        this.setState({
            templateId: templateId
        })
    }

    onProductOrCategoryValidityChanged(valid, identifier, title, type) {
        this.setState({
            productOrCategoryValueValid: valid,
            overwriteApproved: false,
            selectedItem: {
                identifier: identifier,
                title: title,
                type: type
            }
        });

        // Set it back to static, only products support non-static estimates.
        if (type !== null && type !== 'PRODUCT') {
            this.setState({
                pipelineType: "STATIC"
            })
        }
    }

    onTimeValueValidityChanged(valid, value) {
        this.setState({
            timeValueValid: valid,
            timeValue: value
        });
    }

    onDynamicTimeValueValidityChanged(valid, value, timeUnit) {
        this.setState({
            dynamicTimeValueValid: valid,
            dynamicTimeValue: value,
            dynamicTimeUnit: timeUnit
        })
    }

    onEstimateTypeChanged(type) {
        this.setState({
            pipelineType: type
        });
    }

    onSubmitClick() {
        let newPipelineScreenThis = this;
        this.setState({
            pendingRequest: true
        });
        this.context.appFetch(Config.apiHostName + '/app/pipelines', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                identifier: this.state.selectedItem.identifier,
                title: this.state.selectedItem.title,
                type: this.state.selectedItem.type,
                pipelineType: this.state.pipelineType,
                millisecondsToShip: this.state.timeValue,
                eachOrderTimeInMilliseconds: this.state.dynamicTimeValue,
                eachOrderTimeUnit: this.state.dynamicTimeUnit,
                overwriteExisting: this.state.overwriteApproved,
                templateId: this.state.templateId
            })
        }).then(function (response) {
            if (response.status == 200) {
                // TODO: Add a flash message for success.
                newPipelineScreenThis.props.history.push('/')
            } else {
                return response.json().then(function (json) {
                    throw new BackendError(json.code, json.message);
                });
            }
        }).catch(function (ex) {
            // TODO: Handle generic network error here too.
            if (ex.code == ERROR_CODE_PIPELINE_EXISTS) {
                EmbeddedAppUtils.appConfirm(newPipelineScreenThis.context.app, ex.message, function (result) {
                    if (result == true) {
                        newPipelineScreenThis.setState({
                            overwriteApproved: true
                        });
                        newPipelineScreenThis.onSubmitClick()
                    }
                });
            } else {
                EmbeddedAppUtils.appAlert(newPipelineScreenThis.context.app, ex.message);
            }

            newPipelineScreenThis.setState({
                pendingRequest: false
            });
        });
    }

    render() {
        let loading = null;
        if (this.state.pendingRequest) {
            loading = <span>
            &nbsp;&nbsp;
                <img src={loadingGif} />
        </span>
        }

        let dynamicTimeValueFormGroup = null;
        if (this.state.pipelineType === "DYNAMIC") {
            dynamicTimeValueFormGroup = <DynamicTimeValueFormGroup
                initialValue={this.state.dynamicTimeValue}
                initialTimeUnit={this.state.dynamicTimeUnit}
                onValidityChanged={this.onDynamicTimeValueValidityChanged}
                enabled={!this.state.pendingRequest}/>;
        }

        return <div>
            <ProductOrCategoryTypeahead
                initialValue={this.state.selectedItem != null ? this.state.selectedItem.title : null}
                onValidityChanged={(valid, id, title, type) => this.onProductOrCategoryValidityChanged(valid, id, title, type)}
                enabled={!this.state.pendingRequest}/>
            <EstimateType
                initialValue={this.state.pipelineType}
                onValueChanged={this.onEstimateTypeChanged}
                enabled={!this.state.pendingRequest}
                dynamicDisabled={this.state.selectedItem != null && this.state.selectedItem.type != null && this.state.selectedItem.type !== "PRODUCT"}/>
            <TimeValueFormGroup
                initialValue={this.state.timeValue}
                onValidityChanged={(valid, value) => this.onTimeValueValidityChanged(valid, value)}
                enabled={!this.state.pendingRequest}/>
            {dynamicTimeValueFormGroup}
            <TemplateToUse selectedTemplateId={this.state.templateId} onTemplateChanged={this.onHandleTemplateChanged}/>
            <div className="form-row row">
                <div className="col-12">
                    <SubmitButton
                        enabled={
                            this.state.productOrCategoryValueValid
                            && this.state.timeValueValid
                            && (this.state.pipelineType !== "DYNAMIC" || this.state.dynamicTimeValueValid)
                            && !this.state.pendingRequest}
                        onClick={this.onSubmitClick}/>
                    {loading}
                </div>
            </div>
        </div>;
    }
}

export default withRouter(PipelineEditorScreen)