import * as React from "react";

const prettyMs = require('pretty-ms');
import * as Config from "config"
import * as Dialogs from "../utils/embedded_app_utils"
import {Link} from "react-router-dom";
import {storeName} from "../index";
import {ShopifyAppContext} from "../shopify_context";

const pluralize = require('pluralize');

require("./pipeline_item.scss");

export class PipelineItem extends React.Component {
    static contextType = ShopifyAppContext;

    constructor() {
        super();
        this.state = {
            deleting: false
        }
    }

    onDeleteClicked = () => {
        const pipelineItemThis = this;

        Dialogs.appConfirm(this.context.app, "Are you sure you want to delete this estimate?", function (result) {
            if (result === true) {
                pipelineItemThis.setState({
                    deleting: true
                });

                pipelineItemThis.context.appFetch(Config.apiHostName + '/app/pipelines', {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: pipelineItemThis.props.pipeline.id
                    })
                }).then(function (response) {
                    pipelineItemThis.setState({
                        pendingRequest: false
                    });

                    if (response.status === 200) {
                        pipelineItemThis.props.onPipelineDeleted(pipelineItemThis.props.pipeline)
                    } else {
                        const error = new Error(response.text());
                        error.response = response;
                        throw error;
                    }
                }).catch(function (ex) {
                    Dialogs.appAlert(this.context.app, ex);
                    pipelineItemThis.setState({
                        deleting: false
                    });
                })
            }
        })
    }

    componentDidMount() {
        let popupContent = null;
        if (this.props.pipeline.type === "DYNAMIC") {
            popupContent =
                "<table cellpadding='5px'>" +
                "<tr><td>Initial estimate:</td><td>" + prettyMs(this.props.pipeline.timeInMilliseconds, {verbose: true}) + "</td></tr>" +
                "<tr><td>Each order adds:</td><td>" + prettyMs(this.props.pipeline.eachOrderTimeInMilliseconds, {verbose: true}) + "</td></tr>" +
                "<tr><td>Unfulfilled count:</td><td>" + this.props.pipeline.product.unfulfilledCount + " " + pluralize("orders", this.props.pipeline.product.unfulfilledCount) + "</td></tr>" +
                "<tr><td>Public estimate:</td><td>" + prettyMs(this.props.pipeline.roundedEstimateInMilliseconds, {verbose: true}) + "</td></tr>" +
                "<tr><td>Raw estimate:<br/><small>(no rounding)</small></td><td>" + prettyMs(this.props.pipeline.dynamicTimeInMilliseconds, {verbose: true}) + "</td></tr>" +
                "</table>"
        } else if (this.props.pipeline.type === "STATIC") {
            let unfulfilledCount = "";
            if (this.props.pipeline.product !== null) {
                unfulfilledCount = "<tr><td>Unfulfilled count:</td><td>" + this.props.pipeline.product.unfulfilledCount + " " + pluralize("orders", this.props.pipeline.product.unfulfilledCount) + "</td></tr>"
            }

            popupContent =
                "<table cellpadding='5px'>" +
                "<tr><td>Initial estimate:</td><td>" + prettyMs(this.props.pipeline.timeInMilliseconds, {verbose: true}) + "</td></tr>" +
                "<tr><td>Each order adds:</td><td>N/A</td></tr>" +
                unfulfilledCount +
                "<tr><td>Public estimate:</td><td>" + this.props.pipeline.formattedCopy + "</td></tr>" +
                "<tr><td>Raw estimate:<br/><small>(no rounding)</small></td><td>" + prettyMs(this.props.pipeline.timeInMilliseconds, {verbose: true}) + "</td></tr>" +
                "</table>"
        }

        $("#popoverContent-" + this.props.pipeline.id).popover({
            trigger: 'hover',
            html: true,
            title: "Estimate Details",
            content: popupContent,
            placement: "bottom",
            toggle: 'popover'
        })
    }

    render() {
        let trClassName = this.state.deleting ? "deleting" : "";

        let url = null
        if (this.props.pipeline.product !== null) {
            url = "https://" + storeName + "/admin/products/" + this.props.pipeline.product.shopifyId;
        } else {
            url = "https://" + storeName + "/admin/collections/" + this.props.pipeline.collection.shopifyId;
        }

        let actions = this.state.deleting ? <td>Edit / Delete</td> :
            <td>
                <Link to={`/pipeline/${this.props.pipeline.id}`}>Edit</Link> / <a href="#"
                                                                                  onClick={this.onDeleteClicked}>Delete</a>
            </td>;

        let timeCopy = null;

        // TODO: Find some way to inject JSX here instead of gross HTML string.
        if (this.props.pipeline.type === "DYNAMIC") {
            timeCopy = <td>
                <span className="popoverContent" id={"popoverContent-" + this.props.pipeline.id}>
                    {prettyMs(this.props.pipeline.roundedEstimateInMilliseconds, {verbose: true})}
                </span>
            </td>
        } else if (this.props.pipeline.type === "STATIC") {
            timeCopy = <td>
                <span className="popoverContent" id={"popoverContent-" + this.props.pipeline.id}>
                    {prettyMs(this.props.pipeline.roundedEstimateInMilliseconds, {verbose: true})}
                </span>
            </td>
        }

        let title = null;
        let titleBadge = "";
        if (this.props.pipeline.product !== null) {
            title = this.props.pipeline.product.title;
        } else if (this.props.pipeline.collection !== null) {
            title = this.props.pipeline.collection.title;
            titleBadge = <span className="badge badge-pill badge-secondary">Collection</span>;
        }

        return <tr className={trClassName}>
            <td>{titleBadge} <a href={url} target="_blank">{title}</a></td>
            <td>{this.props.pipeline.type.toLowerCase().capitalizeFirstLetter()}</td>
            {timeCopy}
            {actions}
        </tr>
    }
}