import {ClientRouter, RoutePropagator} from "@shopify/app-bridge-react";
import {Route, Switch} from "react-router-dom";
import {PipelinesScreen} from "./screens/PipelinesScreen";
import PipelineEditorScreen from "./screens/PipelineEditorScreen";
import {CollectionsScreen} from "./screens/CollectionsScreen";
import {TemplatesScreen} from "./screens/TemplatesScreen";
import {SettingsScreen} from "./screens/SettingsScreen";
import {HelpScreen} from "./screens/HelpScreen";
import * as React from "react";
import {withRouter} from "react-router";
import {ProductAppLinkRedirect} from "./screens/ProductAppLinkRedirect";
import NewTemplateScreen from "./screens/NewTemplateScreen";

const NoMatch = class NoMatch extends React.Component {
    render() {
        return <div>404 not found.</div>
    }
};

function Routes(props) {
    const { history, location } = props;

    return (
        <>
            <ClientRouter history={history} />
            <RoutePropagator location={location} />
            <Switch>
                <Route exact path="/" component={PipelinesScreen}/>
                <Route path="/pipeline/:pipelineId" component={PipelineEditorScreen}/>
                <Route path="/settings/collections" component={CollectionsScreen}/>
                <Route path="/settings/templates/new" component={NewTemplateScreen}/>
                <Route path="/settings/templates/:templateId" component={NewTemplateScreen}/>
                <Route path="/settings/templates" component={TemplatesScreen}/>
                <Route path="/settings" component={SettingsScreen}/>
                <Route path="/help" component={HelpScreen}/>
                <Route path="/product-app-link" component={ProductAppLinkRedirect}/>
                <Route component={NoMatch}/>
            </Switch>
        </>
    )
}

export default withRouter(Routes);