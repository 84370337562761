import * as React from "react";
import * as Config from "config";
import * as EmbeddedAppUtils from "../utils/embedded_app_utils"
import update from 'immutability-helper';
import {InlineCheckbox} from "../components/InlineCheckbox";
import {EstimateFormatEditor} from "../components/EstimateFormatEditor";
import {FormatTemplateReference} from "../components/FormatTemplateReference";
import {Link} from "react-router-dom";
import {ShopifyAppContext} from "../shopify_context";

const loadingGif = require("../images/loading.gif").default;

export class SettingsScreen extends React.Component {
    static contextType = ShopifyAppContext;

    constructor(props) {
        super(props);

        this.state = {
            pendingRequest: true,
            estimateFormatString: "",
            estimateFormatStringValid: false,
            roundingSetting: null,
            weekdays: new Array()
        };

        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleWeekdayChange = this.handleWeekdayChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    handleFormatChange(newFormat, newOutOfStockFormat, isValid) {
        this.setState({
            estimateFormatString: newFormat,
            outOfStockFormatString: newOutOfStockFormat,
            estimateFormatStringValid: isValid
        })
    }

    handleTypeChange(newType) {
        this.setState({
            roundingSetting: newType
        });
    }

    handleWeekdayChange(weekday, checked) {
        if (checked) {
            const weekdays = update(this.state.weekdays, {$push: [weekday]});
            this.setState({
                weekdays: weekdays
            })
        } else {
            const index = this.state.weekdays.indexOf(weekday);
            if (index > -1) {
                const weekdays = update(this.state.weekdays, {$splice: [[index, 1]]});
                this.setState({
                    weekdays: weekdays
                })
            }
        }
    }

    onSubmitClick() {
        // TOOD: Add validation to format string.

        const settingsScreenThis = this;
        settingsScreenThis.setState({
            pendingRequest: true
        });

        this.context.appFetch(Config.apiHostName + "/app/settings", {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                estimateFormatString: settingsScreenThis.state.estimateFormatString,
                outOfStockFormatString: settingsScreenThis.state.outOfStockFormatString,
                roundingSetting: settingsScreenThis.state.roundingSetting,
                weekdays: settingsScreenThis.state.weekdays.join(",")
            })
        })
            .then(function (response) {
                settingsScreenThis.setState({
                    pendingRequest: false
                });

                if (response.status === 200) {
                    EmbeddedAppUtils.appAlert(this.context.app, "Settings successfully saved.")
                } else {
                    EmbeddedAppUtils.appAlert(this.context.app, "Error saving settings.")
                }
            })
    }

    componentDidMount() {
        const settingsScreenThis = this;

        EmbeddedAppUtils.appTitle(this.context.titleBar, "Settings");

        this.context.appFetch(Config.apiHostName + "/app/settings", {
            credentials: 'include'
        })
            .then(function (response) {
                if (response.status === 200) {
                    return response.json()
                } else {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error
                }
            })
            .then(function (json) {
                settingsScreenThis.setState({
                    estimateFormatString: json.estimateFormatString,
                    outOfStockFormatString: json.outOfStockFormatString,
                    estimateFormatStringValid: true,
                    roundingSetting: json.roundingSetting,
                    weekdays: json.weekdays,
                    pendingRequest: false
                });
            })
            .catch(function (ex) {
                EmbeddedAppUtils.appAlert(this.context.app, "Error loading settings.");
                // TOOD: Send to Raven?
            })
    }

    render() {
        let loading = null;
        if (this.state.pendingRequest) {
            loading = <span>
            &nbsp;&nbsp;
                <img src={loadingGif}/>
        </span>
        }

        let weekdays = null;
        if (this.state.roundingSetting === "ROUND_DAY_OF_WEEK") {
            let weekdaysError = null;

            if (this.state.weekdays.length === 0) {
                weekdaysError = <div>
                    <input type="hidden" className="form-control is-invalid"/>
                    <div className="invalid-feedback">
                        Please select at least one weekday.
                    </div>
                </div>
            }

            weekdays = <div className="form-check is-invalid">
                <InlineCheckbox handleChange={this.handleWeekdayChange}
                                value="MONDAY"
                                valid={this.state.weekdays.length > 0}
                                checked={this.state.weekdays.includes("MONDAY")}
                                displayText="Monday"/>
                <InlineCheckbox handleChange={this.handleWeekdayChange}
                                value="TUESDAY"
                                checked={this.state.weekdays.includes("TUESDAY")}
                                displayText="Tuesday"/>
                <InlineCheckbox handleChange={this.handleWeekdayChange}
                                value="WEDNESDAY"
                                checked={this.state.weekdays.includes("WEDNESDAY")}
                                displayText="Wednesday"/>
                <InlineCheckbox handleChange={this.handleWeekdayChange}
                                value="THURSDAY"
                                checked={this.state.weekdays.includes("THURSDAY")}
                                displayText="Thursday"/>
                <InlineCheckbox handleChange={this.handleWeekdayChange}
                                value="FRIDAY"
                                checked={this.state.weekdays.includes("FRIDAY")}
                                displayText="Friday"/>
                <InlineCheckbox handleChange={this.handleWeekdayChange}
                                value="SATURDAY"
                                checked={this.state.weekdays.includes("SATURDAY")}
                                displayText="Saturday"/>
                <InlineCheckbox handleChange={this.handleWeekdayChange}
                                value="SUNDAY"
                                checked={this.state.weekdays.includes("SUNDAY")}
                                displayText="Sunday"/>

                {weekdaysError}
            </div>;
        }

        return <div>
            <EstimateFormatEditor
                loading={this.state.pendingRequest}
                estimateFormatString={this.state.estimateFormatString}
                estimateFormatStringValid={this.state.estimateFormatStringValid}
                outOfStockFormatString={this.state.outOfStockFormatString}
                onEstimateFormatChanged={this.handleFormatChange}/>

            <div className="row">
                <div className="col-3"/>
                <div className="col-9">
                    <p>
                        Want more than one format? <Link to="/settings/templates">Add more custom templates here</Link>, then choose what template you want for each estimate when creating and editing estimates.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <label htmlFor="TODO" className="col-form-label col-form-label-lg">Estimate Rounding</label>
                </div>
                <div className="col-9">
                    <fieldset className="form-group">
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio"
                                       className="form-check-input"
                                       disabled={this.state.pendingRequest}
                                       name="roundingSetting"
                                       value="ROUND_UP"
                                       onChange={e => this.handleTypeChange(e.target.value)}
                                       checked={this.state.roundingSetting === "ROUND_UP"}/>
                                <span>Round up to the next day</span>
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio"
                                       className="form-check-input"
                                       disabled={this.state.pendingRequest}
                                       name="roundingSetting"
                                       value="ROUND_DOWN"
                                       onChange={e => this.handleTypeChange(e.target.value)}
                                       checked={this.state.roundingSetting === "ROUND_DOWN"}/>
                                <span>Round down to the previous day</span>
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio"
                                       className="form-check-input"
                                       disabled={this.state.pendingRequest}
                                       name="roundingSetting"
                                       value="ROUND_CLOSEST"
                                       onChange={e => this.handleTypeChange(e.target.value)}
                                       checked={this.state.roundingSetting === "ROUND_CLOSEST"}/>
                                <span>Round to the nearest day</span>
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio"
                                       className="form-check-input"
                                       disabled={this.state.pendingRequest}
                                       name="roundingSetting"
                                       value="ROUND_DAY_OF_WEEK"
                                       onChange={e => this.handleTypeChange(e.target.value)}
                                       checked={this.state.roundingSetting === "ROUND_DAY_OF_WEEK"}/>
                                <span>Round to specific day of week <small>(useful for shops that only ship on certain days)</small></span>
                            </label>
                        </div>

                        {weekdays}
                    </fieldset>

                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <button type="submit"
                            className="btn btn-primary btn-lg"
                            disabled={this.state.pendingRequest || !this.state.estimateFormatStringValid || (this.state.roundingSetting === "ROUND_DAY_OF_WEEK" && this.state.weekdays.length === 0)}
                            onClick={this.onSubmitClick}>
                        Save
                    </button>
                    {loading}
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-12">
                    <FormatTemplateReference/>
                </div>
            </div>
        </div>
    }
}