import * as React from "react";

export class FormatTemplateReference extends React.Component {
    render() {
        return (
            <div>
                <h4>Format Template Reference</h4>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Format Placeholder</th>
                        <th scope="col">Description</th>
                    </tr>
                    </thead>
                    <tr>
                        <td><code>NUMBER_OF_DAYS</code></td>
                        <td>Replaced with the number of days to ship.</td>
                    </tr>
                    <tr>
                        <td><code>NUMBER_OF_WEEKS</code></td>
                        <td>Replaced with the number of weeks to ship.</td>
                    </tr>
                    <tr>
                        <td><code>MONTH</code></td>
                        <td>Replaced with the name of the month for when the item will ship.</td>
                    </tr>
                    <tr>
                        <td><code>DAY_OF_MONTH</code></td>
                        <td>Replaced with the day of the month when the item will ship.</td>
                    </tr>
                </table>
            </div>
        )
    }
}