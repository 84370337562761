import * as Config from "config"
import {Button, Modal} from "@shopify/app-bridge/actions"

var mixpanel = require('mixpanel-browser');

function appAlert(app, message) {
    const okButton = Button.create(app, {label: 'OK'});
    const modalOptions = {
        title: ' Alert',
        message: message,
        footer: {
            buttons: {
                primary: okButton
            }
        }
    }

    const modal = Modal.create(app, modalOptions)

    okButton.subscribe(Button.Action.CLICK, () => {
        modal.dispatch(Modal.Action.CLOSE)
    });

    modal.dispatch(Modal.Action.OPEN);
}

function appConfirm(app, message, callbackFunction) {
    const okButton = Button.create(app, {label: 'OK'});
    const cancelButton = Button.create(app, {label: 'Cancel'})
    const modalOptions = {
        title: ' Alert',
        message: message,
        footer: {
            buttons: {
                primary: okButton,
                secondary: [cancelButton]
            }
        }
    }

    const modal = Modal.create(app, modalOptions)

    okButton.subscribe(Button.Action.CLICK, () => {
        modal.dispatch(Modal.Action.CLOSE)
        callbackFunction(true)
    });

    cancelButton.subscribe(Button.Action.CLICK, () => {
        modal.dispatch(Modal.Action.CLOSE)
        callbackFunction(false)
    });

    modal.dispatch(Modal.Action.OPEN);
}

function appTitle(titleBar, title) {
    titleBar.set({title: title})

    if (Config.mixpanelApiKey != null) {
        mixpanel.track(title.toLowerCase())
    }
}


export {
    appAlert as appAlert,
    appConfirm as appConfirm,
    appTitle as appTitle
}