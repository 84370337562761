// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/loading.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".suggestions-container{display:block;position:absolute;width:100%;left:0;padding-top:0;padding-bottom:0;padding-left:15px;padding-right:15px;margin-top:-2px;z-index:2}.react-autosuggest__container--open .suggestions-input{border-bottom-left-radius:0;border-bottom-right-radius:0}.react-autosuggest__container--open .suggestions-container ul li.list-group-item{border-top-left-radius:0;border-top-right-radius:0}.loading{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:25px 25px;background-position:right center;background-repeat:no-repeat}\n", "",{"version":3,"sources":["webpack://./components/product_or_category_typeahead.scss"],"names":[],"mappings":"AAAA,uBAAuB,aAAa,CAAC,iBAAiB,CAAC,UAAU,CAAC,MAAM,CAAC,aAAa,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,kBAAkB,CAAC,eAAe,CAAC,SAAS,CAAC,uDAAuD,2BAA2B,CAAC,4BAA4B,CAAC,iFAAiF,wBAAwB,CAAC,yBAAyB,CAAC,SAAS,wDAA6C,CAAC,yBAAyB,CAAC,gCAAgC,CAAC,2BAA2B","sourcesContent":[".suggestions-container{display:block;position:absolute;width:100%;left:0;padding-top:0;padding-bottom:0;padding-left:15px;padding-right:15px;margin-top:-2px;z-index:2}.react-autosuggest__container--open .suggestions-input{border-bottom-left-radius:0;border-bottom-right-radius:0}.react-autosuggest__container--open .suggestions-container ul li.list-group-item{border-top-left-radius:0;border-top-right-radius:0}.loading{background-image:url(\"../images/loading.gif\");background-size:25px 25px;background-position:right center;background-repeat:no-repeat}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
