import * as React from "react";
import Autosuggest from "react-autosuggest";
import 'whatwg-fetch'
import * as Config from "config";
import {debounce} from "../index";
import {ShopifyAppContext} from "../shopify_context";

require("./product_or_category_typeahead.scss");

export class ProductOrCategoryTypeahead extends React.Component {
    static contextType = ShopifyAppContext;

    constructor() {
        super();

        this.state = {
            loading: false,
            valid: false,
            value: '',
            suggestions: [],
            networkError: null
        };

        this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 300)
    }

    onSuggestionsFetchRequested = ({value}) => {
        const typeaheadThis = this;

        typeaheadThis.setState({
            loading: true
        });

        this.context.appFetch(Config.apiHostName + '/app/items/search?query=' + encodeURIComponent(value), {credentials: 'include'})
            .then(function (response) {
                if (response.status === 200) {
                    return response
                } else {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error
                }
            })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                typeaheadThis.setState({
                    loading: false
                });

                if (json.results.length === 0) {
                    // TODO: No results!
                } else {
                    typeaheadThis.setState({
                        suggestions: json.results,
                        networkError: null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error.message);
                typeaheadThis.setState({
                    networkError: error.message
                });
            });
    };

    getSuggestionValue = suggestion => suggestion.title;

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });
        this.props.onValidityChanged(false, null, null, null)
    };

    render() {
        const {value, suggestions} = this.state;

        const typeaheadThis = this;

        const renderSuggestionsContainer = ({containerProps, children, query}) => {
            let value = typeaheadThis.state.value;
            let noResultsMessage = null;

            if (value.length > 0 && children == null && !this.state.loading && !this.state.valid) {
                noResultsMessage = <ul className="list-group">
                    <li className="list-group-item list-group-item-warning">
                        No results for “{value}”
                    </li>
                </ul>;
            }

            return (
                <div {...containerProps}>
                    {children}
                </div>
            );
        }

        const onSuggestionsClearRequested = () => {
            this.setState({
                suggestions: []
            });
        };

        const onSuggestionSelected = (event, data) => {
            this.props.onValidityChanged(true, data.suggestion.id, data.suggestion.title, data.suggestion.type);
        };

        const renderSuggestion = suggestion => {
            let titleBadge = "";
            if (suggestion.type === "SMART_COLLECTION" || suggestion.type === "CUSTOM_COLLECTION") {
                titleBadge = <span className="badge badge-pill badge-secondary">Collection</span>;
            }

            return <span>
                <a href="#">{titleBadge} {suggestion.title}</a>
            </span>
        };

        const theme = {
            container: 'react-autosuggest__container',
            containerOpen: 'react-autosuggest__container--open',
            input: 'suggestions-input',
            suggestionsContainer: 'suggestions-container',
            suggestionsList: 'suggestions-list list-group',
            suggestion: 'react-autosuggest__suggestion list-group-item list-group-item-action',
            suggestionFocused: 'react-autosuggest__suggestion--focused bg-faded',
            sectionContainer: 'react-autosuggest__section-container',
            sectionTitle: 'react-autosuggest__section-title'
        };

        let networkError = null;
        let formGroupErrorClass = null;
        let formControlErrorClass = null;
        if (this.state.networkError !== null) {
            formControlErrorClass = 'form-control-danger';
            formGroupErrorClass = 'has-danger';
            networkError = <div className="form-control-feedback">Error: {this.state.networkError}.</div>
        }

        // TODO: Figure out appropriate way to get initial value.
        let inputProps = {
            value: this.props.initialValue != null ? this.props.initialValue : value,
            onChange: this.onChange,
            className: "form-control form-control-lg suggestions-input " + formControlErrorClass,
            placeholder: this.props.initialValue == null ? "Product or Category Name" : null,
            disabled: !this.props.enabled
        };

        if (this.state.loading) {
            inputProps.className += " loading";
        }

        return (
            <div className="row">
                <label className="sr-only">Product or Category Name</label>
                <div className={"col-12 form-group " + formGroupErrorClass}>
                    <Autosuggest
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        onSuggestionSelected={onSuggestionSelected}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        suggestions={suggestions}
                        inputProps={inputProps}
                        theme={theme}
                        renderSuggestionsContainer={renderSuggestionsContainer}
                        focusInputOnSuggestionClick={false}
                        focusFirstSuggestion={true}/>
                    {networkError}
                </div>
            </div>
        )
    }
}