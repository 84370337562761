import * as React from 'react';
import * as Config from 'config';
import {ShopifyAppContext} from "../shopify_context";

export class TemplateToUse extends React.Component {
    static contextType = ShopifyAppContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            templates: []
        };

        this.handleTemplateChange = this.handleTemplateChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        const self = this;

        this.context.appFetch(Config.apiHostName + '/app/settings/templates', {
            credentials: 'include'
        }).then(function (response) {
                return response.json()
        }).then(function (json) {
            self.setState({
                loading: false,
                templates: json
            });
        })
    }

    handleTemplateChange({target: {value}}) {
        if (value === 'default') {
            this.props.onTemplateChanged(null);
        } else {
            this.props.onTemplateChanged(value);
        }
    }

    render() {
        const loadingTemplates = this.state.loading ? <option disabled="true">Loading custom templates...</option> : null;

        return (
            <div className={"form-group row"}>
                <label htmlFor="template" className="col-3 col-form-label-lg col-form-label">
                    Display Template
                </label>
                <div className="col-9">
                    <select className="custom-select form-control form-control-lg" name="template" id="template"
                        onChange={this.handleTemplateChange}>
                        <option value="default">Store Default</option>
                        {loadingTemplates}
                        {this.state.templates.map((value, index) => (
                            <option
                                value={value.id}
                                key={value.id}
                                selected={this.props.selectedTemplateId === value.id}>
                                {value.name}
                            </option>
                            ))}
                            </select>
                </div>
            </div>
        )
    }
}