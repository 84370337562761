import * as React from "react";
import * as Config from "config"
import * as EmbeddedAppUtils from "../utils/embedded_app_utils"
import {PipelineItem} from '../components/PipelineItem';
import {SortableTableHeader, NO_SETTING} from "../components/SortableTableHeader";
import {debounce} from "../index";
import qs from "qs";
import {Link} from "react-router-dom";
import {ShopifyAppContext} from "../shopify_context";

const loadingGif = require("../images/loading.gif").default;

export class PipelinesScreen extends React.Component {
    static contextType = ShopifyAppContext;

    constructor(props) {
        super(props);

        const parsedQueryParams = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})

        this.state = {
            loading: false,
            pipelines: [],
            error: null,
            sort: 'title',
            sortOrder: 'asc',
            currentPage: parsedQueryParams.page != null ? parseFloat(parsedQueryParams.page) : 0,
            totalPages: null,
            query: '',
        };

        this.changePage = this.changePage.bind(this);
        this.fetchPipelines = this.fetchPipelines.bind(this);
        this.onPipelineDeleted = this.onPipelineDeleted.bind(this);
        this.onSortChanged = this.onSortChanged.bind(this);
        this.queryUpdated = debounce(this.queryUpdated.bind(this), 300);
    }

    componentDidMount() {
        EmbeddedAppUtils.appTitle(this.context.titleBar, "Estimates");

        this.fetchPipelines()
    }

    fetchPipelines() {
        let screenThis = this;

        screenThis.setState({
            loading: true,
            pipelines: []
        });

        let sort = this.state.sort + "_" + this.state.sortOrder;

        this.context.appFetch(Config.apiHostName + '/app/pipelines/?sortOption=' + sort
            + '&page=' + this.state.currentPage
            + '&query=' + encodeURIComponent(this.state.query), {credentials: 'include'})
            .then(function (response) {
                if (response.status === 200) {
                    return response
                } else {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error
                }
            })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                screenThis.setState({
                    loading: false,
                    pipelines: json.pipelines,
                    totalPages: json.paginationInfo.totalPages,
                    error: null
                })
            })
            .catch(function (error) {
                console.error(error);
                screenThis.setState({
                    loading: false,
                    error: "Unable to fetch pipelines."
                })
            });
    }

    onPipelineDeleted(pipeline) {
        let pipelines = this.state.pipelines;
        pipelines.splice(pipelines.indexOf(pipeline), 1);
        this.setState({
            pipelines: pipelines
        });
    }

    onSortChanged(newSort, newSortOrder) {
        this.setState({
            sort: newSort,
            sortOrder: newSortOrder,
            currentPage: 0,
            totalPages: null
        }, this.fetchPipelines);
    }

    changePage(newPage) {
        this.setState({
            currentPage: newPage,
            totalPages: null
        }, this.fetchPipelines);
    }

    queryUpdated(newQuery) {
        this.setState({
            currentPage: 0,
            totalPages: null,
            query: newQuery
        }, this.fetchPipelines)
    }

    render() {
        let screenThis = this;

        let renderedPipelines = [];
        for (let pipeline of this.state.pipelines) {
            renderedPipelines.push(<PipelineItem key={pipeline.id} pipeline={pipeline}
                                                 onPipelineDeleted={this.onPipelineDeleted}/>)
        }

        let loading = null;
        let firstTimeCopy = null;
        let noSearchResults = null;
        let error = null;
        if (this.state.loading) {
            loading = <tr>
                <td colSpan="4" style={{textAlign: 'center'}}>
                     <span>
                         <img src={loadingGif}/>
                     </span>
                </td>
            </tr>
        } else {
            if (this.state.error !== null) {
                error = <tr>
                    <td colSpan="4" style={{textAlign: 'center'}}>
                        {this.state.error}
                        <br/><br/>
                        <button type="button" className="btn btn-primary" onClick={this.fetchPipelines}>
                            Retry
                        </button>
                    </td>
                </tr>
            } else if (this.state.query === '' && renderedPipelines.length === 0) {
                // TODO: This generates a warning.
                         firstTimeCopy = <tr>
                             <td colSpan="4" style={{textAlign: 'center'}}>
                                 <h4><Link to="/pipeline/new">Create your first estimate to get started</Link></h4>
                                 <p>
                                     Need more help? <Link to="/help">Check out the help page</Link>.
                                 </p>
                             </td>
                         </tr>;
            } else if (this.state.query !== '' && renderedPipelines.length === 0) {
                noSearchResults = <tr>
                    <td colSpan="4" style={{textAlign: 'center'}}>
                        <h4>No Results</h4>
                    </td>
                </tr>;
            }
        }

        let paginationControls = null;
        if (this.state.totalPages > 1) {
            let previousPageDisabled = null;
            if (this.state.currentPage <= 0) {
                previousPageDisabled = "disabled"
            }

            let nextPageDisabled = null;
            if (this.state.currentPage >= this.state.totalPages) {
                nextPageDisabled = "disabled"
            }

            paginationControls = <nav>
                <ul className="pagination justify-content-center">
                    <li className={"page-item " + previousPageDisabled}>
                        <a className="page-link"
                           href="#"
                           tabIndex="-1"
                           onClick={e => this.changePage(this.state.currentPage - 1)}>
                            Previous
                        </a>
                    </li>
                    <li className={"page-item " + nextPageDisabled}>
                        <a className="page-link"
                           href="#"
                           onClick={e => this.changePage(this.state.currentPage + 1)}>
                            Next
                        </a>
                    </li>
                </ul>
            </nav>;
        }

        const nameHeaderStyle = {
            width: '50%'
        };

        const typeHeaderStyle = {
            width: '16%'
        };

        const estimateHeaderStyle = {
            width: '16%'
        };

        const actionsHeaderStyle = {
            width: '10%'
        };

        return <div>
            <div className="row">
                <div className="col-12">
                    <div className="input-group">
                        <input type="text"
                               onChange={e=> this.queryUpdated(e.target.value)}
                               className="form-control"
                               placeholder="Enter text to filter products..."/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <table className="table">
                        <thead>
                        <tr>
                            <SortableTableHeader
                                style={nameHeaderStyle}
                                value="Name"
                                currentSort={this.state.sort === "title" ? this.state.sortOrder : NO_SETTING}
                                onSortChanged={
                                    direction => this.onSortChanged("title", direction)
                                }/>
                            <SortableTableHeader
                                style={typeHeaderStyle}
                                value="Type"
                                currentSort={this.state.sort === "type" ? this.state.sortOrder : NO_SETTING}
                                onSortChanged={
                                    direction => this.onSortChanged("type", direction)
                                }/>
                            <SortableTableHeader
                                style={estimateHeaderStyle}
                                value="Estimate"
                                currentSort={this.state.sort === "current_estimate" ? this.state.sortOrder : NO_SETTING}
                                onSortChanged={
                                    direction => this.onSortChanged("current_estimate", direction)
                                }/>
                            <th style={actionsHeaderStyle}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {error}
                        {loading}
                        {firstTimeCopy}
                        {noSearchResults}
                        {renderedPipelines}
                        </tbody>
                    </table>

                    {paginationControls}
                </div>
            </div>
        </div>;
    }
}
