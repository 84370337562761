import * as React from 'react';

function isFormatValid(format, outOfStockFormat) {
    return (outOfStockFormat != null && outOfStockFormat.length > 0) || (format.includes("NUMBER_OF_DAYS")
        || format.includes("NUMBER_OF_WEEKS")
        || (format.includes("DAY_OF_MONTH") && format.includes("MONTH")));
}

export class EstimateFormatEditor extends React.Component {
    constructor(props) {
        super(props);
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.defaultFormatRef = React.createRef();
        this.outOfStockFormatRef = React.createRef();
    }

    handleFormatChange() {
        const newFormat = this.defaultFormatRef.current.value;
        const newOutOfStockFormat = this.outOfStockFormatRef.current.value;
        this.props.onEstimateFormatChanged(newFormat, newOutOfStockFormat, isFormatValid(newFormat, newOutOfStockFormat));
    }

    render() {
        let invalidValueError = null;
        let invalidValueInputClass = null;

        let invalidOutOfStockValueError = null;
        let invalidOutOfStockValueInputClass = null;

        if (!isFormatValid(this.props.estimateFormatString, this.props.outOfStockFormatString)) {
            invalidValueInputClass = "is-invalid";
            invalidValueError = <div className="invalid-feedback">
                Estimate format must contain NUMBER_OF_DAYS placeholder, NUMBER_OF_WEEKS placeholder, or MONTH and
                DAY_OF_MONTH placeholder.
            </div>
        }

        return (
            <div>
                <div className="form-group row">
                    <div className="col-3">
                        <label className="col-form-label col-form-label-lg">Estimate Format</label>
                    </div>
                    <div className="col-9">
                        <input type="text"
                               ref={this.defaultFormatRef}
                               className={"form-control-lg form-control " + invalidValueInputClass}
                               disabled={this.props.loading}
                               value={this.props.estimateFormatString}
                               onChange={e => this.handleFormatChange()}
                               id="estimateFormat"
                               name="estimateFormat"/>

                        {invalidValueError}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-3">
                        <label className="col-form-label col-form-label-lg">Out of Stock Format</label>
                    </div>
                    <div className="col-9">
                        <input type="text"
                               ref={this.outOfStockFormatRef}
                               className={"form-control-lg form-control " + invalidOutOfStockValueInputClass}
                               disabled={this.props.loading}
                               value={this.props.outOfStockFormatString}
                               onChange={e => this.handleFormatChange()}
                               id="outOfStockEstimateFormat"
                               aria-describedby="outOfStockEstimateFormatHelp"
                               name="outOfStockEstimateFormat"/>

                        <small id="outOfStockEstimateFormatHelp" className="form-text text-muted">
                            (Optional) When specified, this template will be used when the item is out of stock.
                        </small>

                        {invalidOutOfStockValueError}
                    </div>
                </div>
            </div>
        )
    }
}